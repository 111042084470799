import { Skeleton } from '@/components/ui/skeleton'
import React from 'react'

const shortCardSkeleton = ({ count }: { count: number }) => {
  return [...Array(count)].map((_, index) => (
    <div key={index}>
      <div
        className={`relative cursor-pointer w-[300px] flex flex-col gap-4 rounded-sm border overflow-hidden  mobile-width`}
      >
        <Skeleton
          className={`relative w-[300px] h-[11rem] overflow-hidden rounded-t-sm mobile-width`}
        />
        <div className=" flex flex-col gap-3 p-4 pt-0">
          <Skeleton className="w-40 h-4" />
          <Skeleton className="w-60 h-4" />
          <Skeleton className="w-60 h-4" />
          <Skeleton className="w-20 h-5 rounded-lg" />
          <Skeleton className="w-40 h-4" />
          <Skeleton className="w-40 h-4" />
          <Skeleton className="w-40 h-4" />
        </div>
      </div>
    </div>
  ))
}

export default shortCardSkeleton
