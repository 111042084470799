import React from 'react'
import { useTranslation } from '@/app/i18n/client'
import Image from 'next/image'

interface PlanLogoProps {
  planType: string
}
const PlanLogo: React.FC<PlanLogoProps> = ({ planType }) => {
  const { t } = useTranslation()

  const planLogo = (plan: string) => {
    const plans: any = {
      Silver: {
        image: 'Silver.svg',
        text: t('Silver'),
        color: '#929497',
      },
      Gold: { image: 'Gold.svg', text: t('Gold'), color: '#df7d2f' },
      Platinum: {
        image: 'Platinum.svg',
        text: t('Platinum'),
        color: '#42484f',
      },
    }
    return plans[plan]
  }

  const subscription = planLogo(planType)
  return (
    <div className="flex items-baseline gap-1">
      <Image
        height={15}
        width={15}
        className="!h-[13.26px] !w-[15px]"
        alt="crown"
        src={`/assets/Icons/crown/${subscription?.image}`}
      />
      <span className="text-[16px]" style={{ color: subscription?.color }}>
        {t(subscription?.text)}
      </span>
    </div>
  )
}

export default PlanLogo
