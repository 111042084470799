'use client'
import { RootState } from '@/app/globalRedux/store'
import { updateLocalStorageCount } from '@/lib/utils'
import { sendGTMEvent } from '@next/third-parties/google'
import { useParams, usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export interface BuyersAlertTrackerType {
  buyers_alert_card_id?: string
  buyers_alert_card_clicked_id?: string
  buyers_alert_card_lang: string
  buyers_alert_card_company_id: string
  userId: number
  isAllCookiesEnabled: boolean
}

export const useGTMBuyerTracker = (companyId: number, userId: number) => {
  const pathName = usePathname()
  const params = useParams()
  const { isAllCookiesEnabled } = useSelector((state: RootState) => state.user)
  useEffect(() => {
    if (companyId) {
      updateLocalStorageCount(companyId, 'buyer_alert_detail_visit')
    }

    if (companyId) {
      sendGTMEvent({
        event: 'buyer_alert_detail_visit',
        buyers_alert_id: params?.buyerAlertId,
        company_id: companyId,
        lang: params?.lng ?? 'en',
        current_user_id:
          userId > 0
            ? isAllCookiesEnabled
              ? String(userId)
              : 'not-consented'
            : 'non-logged-in',
      })
    }
  }, [pathName, companyId])
}

export const useContactBuyerBuyerAlertGTMEvent = () => {
  const sendContactBuyerEvent = ({
    buyerAlertID,
    companyID,
  }: {
    buyerAlertID: number
    companyID: number
  }) => {
    if (buyerAlertID) {
      updateLocalStorageCount(buyerAlertID, 'buyer_alert_contact_buyer_click')
    }
    sendGTMEvent({
      event: 'buyer_alert_contact_buyer_click',
      buyers_alert_id: buyerAlertID,
      company_id: companyID,
    })
  }

  return sendContactBuyerEvent
}

export const GTMABACardViewTracker = ({
  buyers_alert_card_id,
  buyers_alert_card_lang,
  buyers_alert_card_company_id,
  userId,
  isAllCookiesEnabled,
}: BuyersAlertTrackerType) => {
  if (buyers_alert_card_id) {
    updateLocalStorageCount(buyers_alert_card_id, 'buyers_alert_card_view')
  }
  sendGTMEvent({
    event: 'buyers_alert_card_view',
    buyers_alert_id: buyers_alert_card_id,
    lang: buyers_alert_card_lang,
    company_id: buyers_alert_card_company_id,
    current_user_id:
      userId > 0
        ? isAllCookiesEnabled
          ? String(userId)
          : 'not-consented'
        : 'non-logged-in',
  })
}

export const GTMABACardClickTracker = ({
  buyers_alert_card_clicked_id,
  buyers_alert_card_lang,
  buyers_alert_card_company_id,
  userId,
  isAllCookiesEnabled,
}: BuyersAlertTrackerType) => {
  if (buyers_alert_card_clicked_id) {
    updateLocalStorageCount(
      buyers_alert_card_clicked_id,
      'buyers_alert_card_clicked'
    )
  }
  sendGTMEvent({
    event: 'buyers_alert_card_clicked',
    buyers_alert_id: buyers_alert_card_clicked_id,
    lang: buyers_alert_card_lang,
    company_id: buyers_alert_card_company_id,
    current_user_id:
      userId > 0
        ? isAllCookiesEnabled
          ? String(userId)
          : 'not-consented'
        : 'non-logged-in',
  })
}
