import { apiSlice } from './apiSlice'

export const adSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAds: builder.query({
      query: (params: { page: string | any; lang: string }) => ({
        url: `/banner_schedules?page=${params.page}&lang=${params.lang}`,
        method: 'GET',
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
  }),
})

export const { useGetAdsQuery } = adSlice
